import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const UsefulTriangle = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="A Useful Triangle" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>A Useful Triangle </p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Dec. 4, 2021) </p>
        </div>
        <div className="articleBody">
          <p>Life can be a bit strange. </p>
          <p>
            2021 has very possibly been the best year of my life so far. And
            yet, it's also the year in which I experienced the end of a 20-year
            friendship. I'm writing this to clarify that contradiction for
            myself, because I'm not sure how both of those things can be true.
          </p>
          <p>
            As per usual, I took deliberate time to reflect and heal and
            theorize when that tragedy came to pass. What I forged in the flames
            of that process is a shiny new tool for understanding all of my
            relationships.
          </p>
          <p>
            In order to measure and predict the state of a given relationship
            (of any kind), there are three operative forces to consider:{" "}
            <span className="editorsNote">[JOY]</span>,{" "}
            <span className="editorsNote">[RESPECT]</span>, and{" "}
            <span className="editorsNote">[LOVE]</span>. Those forces make up
            the three sides of a triangle, the tool I'm referring to. I
            discovered that in order to confidently call any single relationship
            sustainable, it has to satisfy <str>at least two</str> of the three
            sides of that triangle for all parties.
          </p>
          <p>
            Let me elaborate: I can maintain successful relationships with the
            people I like and respect (my work colleagues, my comrades). The
            same is true of those I both respect and love (the relatives I know
            I can rely on, despite not understanding the memes they like). I can
            even keep up a relationship with loved ones who have broken my trust
            or shown poor judgement if I still find them a joy to be with.
          </p>
          <p>
            But if I <i>only</i> like you, you're probably the nice lady with
            the Boxer who I greet every now and then on my runs. If I{" "}
            <i>only</i> respect you, you're probably someone whose book I
            learned a lot from. And if I <i>only</i> love you, if I no longer{" "}
            <i>like</i> or <i>respect</i> you, then you're the so-called brother
            I lost this year.
          </p>
          <p>
            I'm choosing to call it the Blake Triangle (cuz James Blake put out{" "}
            <a
              href="https://music.apple.com/us/album/friends-that-break-your-heart-bonus/1588420070"
              target="_blank"
              rel="noopener noreferrer"
            >
              this album
            </a>{" "}
            with uncanny timing), not to be confused with a few similar
            triangular concepts I just discovered in a quick DuckDuckGo search
            while writing this all out (see{" "}
            <a
              href="http://www.robertjsternberg.com/love"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>{" "}
            and{" "}
            <a
              href="https://www.wheretotalk.org/news/relationship-triangle/"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            ).
          </p>
          <p>
            So here's how to use it: imagine you're holding your triangle in one
            hand, and a rod in the other (just like the{" "}
            <a
              href="https://en.wikipedia.org/wiki/Triangle_(musical_instrument)"
              target="_blank"
              rel="noopener noreferrer"
            >
              instrument
            </a>
            ). Now think of someone, anyone. Strike the triangle with the rod.
            If your relationship with that someone only satisfies one side of
            the triangle, you'll either hear a sour note or no note at all. If
            the relationship meets two sides, you'll hear a crystal clear note
            that'll echo all around you for quite some time. And if that
            relationship contains all three components that make up the
            triangle, you'll he--
          </p>
          <p>
            <span className="editorsNote">
              <str>
                ???01100011
                <br />
                [WAVECRASH//
                <br />
                BIRDSONG//
                <br />
                FIRECRACKLE//
                <br />
                RAINFALL//
                <br />
                THUNDERCLAP//
                <br />
                HEARTBEAT]
                <br />
                01100011???
              </str>
            </span>
          </p>
          <p>Well, you'll hear some shit that only you can hear, really.</p>
          <p>
            And I lie to y'all not, my eureka moment just came while I was
            writing that last paragraph!!! At the top, I said I was writing this
            to clarify a contradiction for myself. When I said that, I thought I
            had an answer already. I thought I could explain it like this: the
            reason I can have an amazing year despite experiencing a loss is
            that when I hold my triangle up to all my other relationships, I
            hear beautiful things. And while that's very true, it's not the
            whole truth.
          </p>
          <p>
            The rest of the truth is that I've learned to put the triangle down
            whenever I please.
          </p>
          <p>ٱلْحَمْدُ لِلَّٰهِ رَبِّ ٱلْعَٰلَمِينَ</p>
        </div>
        <div className="articleCopyright">
          <p className="plug">
            If you enjoyed this article, consider{" "}
            <a href="https://www.patreon.com/join/shoyaright?">
              becoming a patron
            </a>{" "}
            or making a{" "}
            <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
              one-time pledge
            </a>
            . Support corporate journalism!
          </p>
          <p>&copy; Shoya, Inc. 2021</p>
        </div>
      </div>
    </div>
  )
}
export default UsefulTriangle
